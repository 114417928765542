import React, { useState, useEffect } from "react"
import "./dashboard-settings.css"
import axios from "../../../components/axios"
import ClipLoader from "react-spinners/ClipLoader"
import "react-dropdown-now/style.css"
import { Icon } from "@iconify/react"
import "./dashboard-team.css"
import { atom, useAtom } from "jotai"


// const firstNameAtom = atom("")
// const lastNameAtom = atom("")
// const emailAtom = atom("")
// const phoneAtom = atom("")
// const roleAtom = atom("")

const popUpAtom = atom(false)

const loadingAtom = atom(false)
// const [filterStatus, setFilterStatus] = useState("all")
// const [filter, setFilter] = useState("all")
// const [showFilter, setShowFilter] = useState(false)

const AddTeamPopUp = ({ setPopUpShown, setLoading }) => {
  // const [fName, setFNameA] = useState("")
  // const [first_name] = useAtom("")

  // const [last_name, setLName] = useState("")
  const [email, setMail] = useState("")
  const [phone, setPhone] = useState("")
  const [role, setRole] = useState("Admin")
  // const [loading, setLoading] = useAtom(loadingAtom)


  // const [isPopUpShown, setPopUpShown] = useAtom(popUpAtom);

  const add_team = (ev) => {

    ev.preventDefault()

    setLoading(true)

    let data = {
      first_name: role,
      last_name: 'User',
      email,
      phone,
      role,
      id: localStorage.getItem("id"),
      // loading,
      // isPopUpShown,
      team_id: localStorage.getItem('id')
    }

    if (!['Admin', 'Developer', 'User'].includes(role) || !phone || !email
      // || !fName || !last_name
    ) {
      setLoading(false)
      alert("Please fill all fields")
      return false
    } else {
      axios.post('/add-team-member', data)
        .then(res => {
          setLoading(false)
          if (res.data.message === "success") {
            setPopUpShown(false)
            window.location.reload()
          } else {

          }
        })
        .catch(err => {

        })
    }
  }

  return (
    <div className="bg-[rgba(0,0,0,0.7)] flex flex-1 w-full h-full top-28 left-0 justify-center items-center absolute">
      <div className="bg-white w-[50%] h-[50%] mb-[10%] p-10 rounded-2xl">
        <Icon icon={'material-symbols:close'} className="w-6 h-6 absolute" onClick={() => { setPopUpShown(false) }} />
        <p
          className="text-primary text-2xl text-center mt-1"
          style={{
            fontFamily: "cabinet-grotesk-bold",
            fontStyle: "normal",
            fontWeight: 800,
          }}
        >
          Add Team Role
        </p>

        {/* <div className="flex flex-row mt-7" >
          <span className="flex flex-col mt-7 mr-5">
            <label
              className="text-lg"
              style={{
                fontFamily: "cabinet-grotesk-bold",
                fontStyle: "normal",
                fontWeight: 500,
              }}
            // htmlFor=""
            >
              First Name
            </label>
            <input
              className="border-primary h-12 rounded-md mt-2 p-4"
              style={{ borderWidth: 1 }}
              type="text"
              placeholder="Input First Name"
              value={fName}
              onChange={(ev) => setFNameA(ev.target.value)}
            />
          </span>

          <span className="flex flex-col mt-7">
            <label
              className="text-lg"
              style={{
                fontFamily: "cabinet-grotesk-bold",
                fontStyle: "normal",
                fontWeight: 500,
              }}

            >
              Last Name
            </label>
            <input
              className="border-primary h-12 rounded-md mt-2 p-4"
              style={{ borderWidth: 1 }}
              type="text"
              placeholder="Input Last Name"
              onChange={(ev) => setLName(ev.target.value)}
            />
          </span>

        </div> */}

        <span className="flex flex-col mt-7">
          <label
            className="text-lg"
            style={{
              fontFamily: "cabinet-grotesk-bold",
              fontStyle: "normal",
              fontWeight: 500,
            }}

          >
            Email
          </label>
          <input
            className="border-primary h-12 rounded-md mt-2 p-4"
            style={{ borderWidth: 1 }}
            type="text"
            placeholder="Input email address"
            onChange={(ev) => setMail(ev.target.value)}
          />
        </span>

        <span className="flex flex-col mt-7">
          <label
            className="text-lg"
            style={{
              fontFamily: "cabinet-grotesk-bold",
              fontStyle: "normal",
              fontWeight: 500,
            }}

          >
            Phone
          </label>
          <input
            className="border-primary h-12 rounded-md mt-2 p-4"
            style={{ borderWidth: 1 }}
            type="text"
            placeholder="Input Phone"
            onChange={(ev) => setPhone(ev.target.value)}
          />
        </span>

        <span className="flex flex-col mt-7">
          <label
            className="text-lg"
            style={{
              fontFamily: "cabinet-grotesk-bold",
              fontStyle: "normal",
              fontWeight: 500,
            }}

          >
            Role
          </label>

          <select
            value={role}
            onChange={(ev) => setRole(ev.target.value)}
            className="border-primary h-12 rounded-md mt-2 p-4"
            style={{ borderWidth: 1 }}
          >
            <option value="Admin">Admin</option>
            <option value="Developer">Developer</option>
            <option value="User">User</option>
          </select>
          {/* <input
            className="border-primary h-12 rounded-md mt-2 p-4"
            style={{ borderWidth: 1 }}
            type="text"
            placeholder="Officiate team role"
            onChange={(ev) => setRole(ev.target.value)}
          /> */}

          <button
            className="bg-[#060D1C] transition duration-500 hover:bg-secondary hover:shadow-secondary text-white p-4 mt-14 rounded-xl"
            type="button"
            onClick={(ev) => add_team(ev)}
          >
            Add Team Role
          </button>
        </span>
      </div>
    </div>
  )
}

export default function TeamRoles({ user, roles }) {
  const [loading, setLoading] = useAtom(loadingAtom)
  const [role_view, setRoles] = useState(roles)

  // const [filterStatus, setFilterStatus] = useState("all")
  // const [filter, setFilter] = useState("all")
  // const [showFilter, setShowFilter] = useState(false)

  const [isPopUpShown, setPopUpShown] = useAtom(popUpAtom);


  useEffect(() => {
    setLoading(false)
    // axios.post('/list-members', {
    //   team_id: localStorage.getItem("id")
    // })
    // .then(res => {

    // })
  })

  const remove_member = (merchant_id) => {
    setLoading(true)
    axios.post('/remove-team-member', {
      merchant_id,
      team_id: localStorage.getItem('id')
    })
      .then(res => {
        setLoading(false)
        if (res.data.message === "success") {
          setRoles(role_view.filter(rs => rs._id !== merchant_id))
        } else {

        }
      })
      .catch(err => {

      })

  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }
  return (
    <main>
      {
        isPopUpShown && (
          <AddTeamPopUp setPopUpShown={setPopUpShown} setLoading={setLoading} />
        )
      }

      <div className="recent-transaction-table transaction-list-container">
        <header>
          <h4>Teams</h4>

          <div className="">
            <button
              className="bg-[#060D1C] text-white p-4 rounded-full"
              type="button"
              onClick={() => { setPopUpShown(true) }}
            >
              Add Team Role
            </button>
          </div>
        </header>

        <table className="recent-transactions">
          <thead>
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th>Action</th>

              {/* <th>Action</th> */}
            </tr>
          </thead>

          <tbody>
            {/* todo: try to abstract this table into the dashboardrecenttxns*/}
            {role_view &&
              role_view
                .filter((tx) => {
                  // if (filterStatus === "all") return tx
                  // if (filter === "type") return tx.type === filterStatus
                  // if (filter === "mode") return tx.meta.mode === filterStatus
                  // if (filter === "status") return tx.status === filterStatus
                  return tx
                })
                .map((trx) => (
                  <tr>
                    <td>{trx.email}</td>
                    <td>{trx.role}</td>
                    <td className="text-purple-950">
                      <span>{trx.status}</span>
                    </td>
                    <td className="text-red-700 cursor-pointer" onClick={() => remove_member(trx._id)}>Delete</td>

                  </tr>
                ))}

            {/* <tr>
              <td>#11100</td>
              <td>Car Rental</td>
              <td>&#8358;120,000:00</td>
              <td className="status">
                <span>Declined</span>
              </td>
              <td>10:00am</td>
              <td>14th Jan, 2022</td>
              <td>1111</td>
            </tr>

            <tr>
              <td>#11100</td>
              <td>Car Rental</td>
              <td>&#8358;120,000:00</td>
              <td className="status">
                <span>Declined</span>
              </td>
              <td>10:00am</td>
              <td>14th Jan, 2022</td>
              <td>1111</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </main>
  )
}

