import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import POS from '../../Transactions/POS';

const DashboardRecentTransaction = ({ transactions, user }) => {

  // const [trxs, setTrxs] = useState(transactions ? transactions : [])
  const [openPrint, setPrint] = useState(false)
  const [transaction, setTrx] = useState({})

  const navigate = useNavigate()

  return (
    openPrint ? <POS trx={transaction} closePrint={() => setPrint(false)} user={user} /> : <>
      <div className="recent-transaction-table">
        <header>
          <h4>Recent Transactions</h4>
          <span onClick={() => navigate('/dashboard/transactions')}>
            <p>View All</p>
          </span>
        </header>

        <main></main>
        <table className="recent-transactions ">
          <thead>
            <tr>
              <th className='hidden md:inline-block'>Id</th>
              <th className=''>Narration</th>
              <th className=''>Amount</th>
              <th className=''>Status</th>
              <th className='hidden md:inline-block'>Time</th>
              <th className='hidden md:inline-block'>Date</th>
              <th className='hidden md:inline-block'>Type</th>
              <th className='hidden md:inline-block'>Print</th>
            </tr>
          </thead>

          <tbody>
            {
              transactions && transactions.map(
                (trx, index) => {
                  const created_date = new Date(trx.createdAt)
                  let hours = created_date.getHours();
                  let period = 'am'
                  if (hours > 12) {
                    hours = hours - 12;
                    period = 'pm';
                  }
                  let minutes = created_date.getMinutes()
                  return (
                    <tr key={index} onClick={() => {
                      setTrx(trx)
                      setPrint(true)
                    }}>
                      <td className='hidden md:inline-block'>{trx._id}</td>
                      <td>{trx.name}</td>
                      <td>&#8358;{trx.amount}</td>
                      <td className={`${trx.status === "success" ? " text-green-600" : " text-red-600"}`}>
                        <span>{trx.status}</span>
                      </td>
                      <td className='hidden md:inline-block' >{`${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${period}`}</td>
                      <td className='hidden md:inline-block'>{
                        `${created_date.getDate()}/${created_date.getMonth() + 1}/${created_date.getFullYear()}`
                      }</td>
                      <td className='capitalize hidden md:inline-block'>{trx.type}</td>
                      <td className='hidden md:inline-block'>Print</td>
                    </tr>
                  )
                }
              )
            }

            {/* <tr>
              <td>#11100</td>
              <td>Car Rental</td>
              <td>&#8358;120,000:00</td>
              <td className="status">
                <span>Declined</span>
              </td>
              <td>10:00am</td>
              <td>14th Jan, 2022</td>
              <td>1111</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DashboardRecentTransaction;
